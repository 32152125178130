import './CatalogoBody.css';
import { SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Pagination, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import LinesEllipsis from 'react-lines-ellipsis';
import { MainBody } from './MainBody';
import { Imagem } from '../generic/Imagem';
import { useDebounce } from '../../hooks';
import UseSessionCatalogo from '../../hooks/UseSessionCatalogo';
import { ParametrosPaginacao } from '../../utils/constants';
import { IListCatalogo, IListFotosCatalogo } from '../../services/api/Interfaces';
import { sessionCatalogoService } from '../../services/api/catalogo/CatalogoService';
import { useTranslation } from 'react-i18next';

interface ICatalogoProps {
  language: string;
}

export const CatalogoBody: React.FC<ICatalogoProps> = ({ language }) => {
  const { t, i18n } = useTranslation();

  const { debounce } = useDebounce();
  const { getAll, getLancamentos } = sessionCatalogoService();
  const { sessionListaCatalogo, setSessionListaCatalogo } = UseSessionCatalogo();
  const [produtos, setProdutos] = useState<IListCatalogo[]>([]);
  const [textoAplicacaoProduto, setTextoAplicacaoProduto] = useState('');
  const [dsProdCatalogo, setDsProdCatalogo] = useState('');
  const [codigoOriginal, setCodigoOriginal] = useState('');
  const [codigoIM, setCodigoIM] = useState('');
  const [dsCatalogoLinha, setDsCatalogoLinha] = useState('');
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const produtosPorPagina = ParametrosPaginacao.PRODUCT_LIMIT_SOURCE;
  const totalPaginas = Math.ceil(produtos.length / produtosPorPagina);

  const handleClose = () => setShow(false);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);

      // console.log('sessionListaCatalogo:', sessionListaCatalogo);
      if (sessionListaCatalogo && sessionListaCatalogo.length !== 0 && sessionCatalogoService !== undefined) {
        setProdutos([]);
        setProdutos(sessionListaCatalogo);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // getAll(ParametrosPaginacao.PRODUCT_MAXLIMIT_SOURCE, 0).then((result) => {
        //   if (result instanceof Error) {
        //     alert(result.message);
        //     return;
        //   } else {
        //     setIsLoading(false);
        //     setProdutos(result);
        //     setSessionListaCatalogo(result);
        //   }
        // });
      }
    });
  }, [
    debounce,
    // getAll,
    getLancamentos,
    sessionListaCatalogo,
    setSessionListaCatalogo
  ]);

  const { getFotosCatalogo } = sessionCatalogoService();
  const [fotosCatalogo, setFotoCatalogo] = useState<IListFotosCatalogo[]>([]);

  const handleShowDialog = (
    cdOriginal: string,
    cdIndustrial: string,
    textoAplicacao: string,
    dsProdCatalogo: string,
    cdWeb: string,
    dsCatalogoLinha: string
  ) => {
    setFotoCatalogo([]);
    setShow(!show);
    getFotosCatalogo(cdIndustrial).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      }
      const uniqueResult = [...new Set(result)];
      setFotoCatalogo(uniqueResult);
      setTextoAplicacaoProduto(textoAplicacao);
      setDsProdCatalogo(dsProdCatalogo);
      setCodigoIM(cdWeb);
      setCodigoOriginal(cdOriginal);
      setDsCatalogoLinha(dsCatalogoLinha);
    });
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: SetStateAction<number>) => {
    setIndex(selectedIndex);
  };

  // Defina o número de produtos exibidos por página
  const [paginaAtual, setPaginaAtual] = useState(1);

  const indiceUltimoProduto = paginaAtual * produtosPorPagina;
  const indicePrimeiroProduto = indiceUltimoProduto - produtosPorPagina;
  const produtosExibidos = produtos.slice(indicePrimeiroProduto, indiceUltimoProduto);

  const handleChangePagina = (novaPagina: number) => {
    setPaginaAtual(novaPagina);
  };

  const renderItensPaginacao = () => {
    const itensPaginacao: JSX.Element[] = [];

    if (totalPaginas <= 5) {
      // Se o número total de páginas for menor ou igual a 5, mostrar todas as páginas
      for (let i = 1; i <= totalPaginas; i++) {
        itensPaginacao.push(
          <Pagination.Item key={i} active={i === paginaAtual} onClick={() => handleChangePagina(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Caso contrário, usar ellipses para mostrar uma parte das páginas
      let firstEllipsis = false;
      let lastEllipsis = false;

      for (let i = 1; i <= totalPaginas; i++) {
        if (i === 1 || i === totalPaginas || (i >= paginaAtual - 1 && i <= paginaAtual + 1)) {
          itensPaginacao.push(
            <Pagination.Item key={i} active={i === paginaAtual} onClick={() => handleChangePagina(i)}>
              {i}
            </Pagination.Item>
          );
        } else {
          if (!firstEllipsis && i < paginaAtual) {
            itensPaginacao.push(<Pagination.Ellipsis key={`firstEllipsis`} />);
            firstEllipsis = true;
          } else if (!lastEllipsis && i > paginaAtual) {
            itensPaginacao.push(<Pagination.Ellipsis key={`lastEllipsis`} />);
            lastEllipsis = true;
          }
        }
      }
    }
    return itensPaginacao;
  };

  return (
    <>
      <MainBody pageClassName='CatalogoClass'>
        <Container>
          <Row>
            <Col className='BoxLinha col'>
              <Pagination>{renderItensPaginacao()}</Pagination>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            {isLoading ? (
              <p>
                <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span> {t('loading')}
              </p>
            ) : (
              <Col>
                {produtosExibidos &&
                  produtosExibidos.map((produto, index) => {
                    return (
                      <Button
                        key={index}
                        className='BTCard'
                        variant='disable'
                        onClick={(_) =>
                          handleShowDialog(
                            produto.cdOriginal,
                            produto.cdIndustrial,
                            i18n.language === 'es'
                              ? produto.textoAplicacao_ESP
                              : i18n.language === 'en'
                              ? produto.textoAplicacao_ING
                              : produto.textoAplicacao,
                            i18n.language === 'es'
                              ? produto.dsProdCatalogo_ESP
                              : i18n.language === 'en'
                              ? produto.dsProdCatalogo_ING
                              : produto.dsProdCatalogo,
                            produto.cdWeb,
                            i18n.language === 'es'
                              ? produto.dsCatalogoLinha_ESP
                              : i18n.language === 'en'
                              ? produto.dsCatalogoLinha_ING
                              : produto.dsCatalogoLinha
                          )
                        }
                      >
                        <Row className='noFlex'>
                          <Col className='NoSpace' xs lg='12'>
                            <div className='box-gray'>
                              <Imagem
                                cdIndustrial={produto.cdIndustrial}
                                dsProdCatalogo={
                                  i18n.language === 'es'
                                    ? produto.dsProdCatalogo_ESP
                                    : i18n.language === 'en'
                                    ? produto.dsProdCatalogo_ING
                                    : produto.dsProdCatalogo
                                }
                              />
                              <p>
                                <strong>{t('catalogFormSearchIMCode')}</strong> <span>{produto.cdWeb}</span>
                              </p>
                              <h3>
                                {i18n.language === 'es'
                                  ? produto.dsProdCatalogo_ESP
                                  : i18n.language === 'en'
                                  ? produto.dsProdCatalogo_ING
                                  : produto.dsProdCatalogo}
                              </h3>
                              <LinesEllipsis
                                text={
                                  i18n.language === 'es'
                                    ? produto.textoAplicacao_ESP
                                    : i18n.language === 'en'
                                    ? produto.textoAplicacao_ING
                                    : produto.textoAplicacao
                                }
                                maxLine='2'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                                className='TextoAplicacaoPruduto'
                              />
                              <p>
                                <strong>{t('catalogFormSearchLine')}:</strong>{' '}
                                <span>
                                  {i18n.language === 'es'
                                    ? produto.dsCatalogoLinha_ESP
                                    : i18n.language === 'en'
                                    ? produto.dsCatalogoLinha_ING
                                    : produto.dsCatalogoLinha}
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Button>
                    );
                  })}
              </Col>
            )}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className='BoxLinha col'>
              <Pagination>{renderItensPaginacao()}</Pagination>
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>{t('catalogFormSearchpartName')}:</div>
              <h6>{dsProdCatalogo}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {show &&
                fotosCatalogo &&
                fotosCatalogo.map((foto, index) => (
                  <Carousel.Item key={index} interval={1000} className='slide'>
                    <img src={foto.base64Imagem} alt={foto.arquivoFoto} onClick={(e) => setShow(true)} />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <strong>{t('catalogFormSearchIMCode')}: </strong> <span>{codigoIM}</span>
              <hr />
            </div>
            <div>
              <strong>{t('catalogFormSearchOEM')}:</strong>
              <span>{codigoOriginal}</span>
            </div>
            <hr />
            <strong>{t('catalogFormSearchApplication')}:</strong>
            <p>{textoAplicacaoProduto}</p>
            <hr />
            <p>
              <strong>{t('catalogFormSearchLine')}:</strong>
              <span>{dsCatalogoLinha}</span>
            </p>
          </Modal.Footer>
        </Modal>
      </MainBody>
    </>
  );
};
