import './MainBanner.css';
import { useEffect, useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';

import { sessionBannerService } from '../../services/api/banner/BannerService';
import { IListBanner } from '../../services/api/Interfaces';
import { Banner01 } from '../../../images';
import { useTranslation } from 'react-i18next';

export const MainBanner: React.FC = () => {
  const { i18n } = useTranslation('banners');
  const { getAll } = sessionBannerService();
  const [banners, setBanners] = useState<IListBanner[]>([]);

  useEffect(() => {
    let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase();
    getAll(language).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      }
      setBanners(result);
    });
  }, [getAll, i18n.language]);

  return (
    <section className='BannerClass'>
      <Container className='NoSpace'>
        <Row className='NoSpace'>
          <Col className='NoSpace ta-center'>
            <Carousel>
              {banners &&
                // eslint-disable-next-line array-callback-return
                banners.map((banner, index) => (
                  <Carousel.Item key={index} interval={5000} className='slide'>
                    <img
                      key={banner.ordemExibicao}
                      src={`${banner.caminhoArquivo ? banner.caminhoArquivo : Banner01}`}
                      alt={banner.nomeBanner}
                      loading='lazy'
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
