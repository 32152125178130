import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';
import { IListBanner } from '../Interfaces';

type TBanner = {
  banners: IListBanner[];
};

const getAll = async (language: string): Promise<IListBanner[] | Error> => {
  try {
    const urlRelativa = `banners/SITE?apikey=${Environment.API_KEY}&idioma=${language}`;
    // console.log('urlRelativa', urlRelativa);
    const { data } = await Api.get<TBanner>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    return data.banners;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os banners');
  }
};

export const sessionBannerService = () => ({
  getAll,
});
