import { MainFooter, HomeBody, MainHeader, MainFiltro, MainBanner, MainLinhas } from '../../shared/components';

export const Home: React.FC = () => {
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBanner />
      <HomeBody />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
