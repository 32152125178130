/* eslint-disable import/no-anonymous-default-export */
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { sessionCatalogoService } from '../services/api/catalogo/CatalogoService';
import { ParametrosPaginacao } from '../utils/constants';
import { sessionListaCatalogoStore } from '../components/store';

export default () => {
  const { getAll, getFiltered } = sessionCatalogoService();

  const [sessionListaCatalogo, setSessionListaCatalogo] = useRecoilState(sessionListaCatalogoStore);

  const handleCatalogo = useCallback(
    (offset: number) => {
      getAll(ParametrosPaginacao.PRODUCT_LIMIT_SOURCE, offset).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
          return;
        }
        return setSessionListaCatalogo(result);
      });
    },
    [getAll, setSessionListaCatalogo]
  );

  const handleSearchCatalogo = useCallback(
    async (offset: number, cdIndustrial: string) => {
      await getFiltered(ParametrosPaginacao.PRODUCT_MAXLIMIT_SOURCE, offset, cdIndustrial).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
          return;
        }

        setSessionListaCatalogo(result);
      });
    },
    [getFiltered, setSessionListaCatalogo]
  );

  return {
    sessionListaCatalogo,
    setSessionListaCatalogo,
    handleCatalogo,
    handleSearchCatalogo,
  };
};
