import './MainFiltro.css';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { UseMontadoras } from '../../hooks';
import UseSessionCatalogo from '../../hooks/UseSessionCatalogo';
import { IcoLinhaAgricola, IcoLinhaLeve, IcoLinhaPesada, IcoLinhaVoltex, IcoNavLancamentos } from '../../../images';
import { sessionCatalogoService } from '../../services/api/catalogo/CatalogoService';
import { IFamilyOptions, sessionFamilyService } from '../../services/api/filtro/FamilyService';
import { IModeloOptions, sessionVehicleService } from '../../services/api/filtro/ModeloService';
import { IListCatalogo } from '../../services/api/Interfaces';
import { ParametrosPaginacao } from '../../utils/constants';
import { ISelectOptions } from '../../services/api/Interfaces/SelectOptions';

export const tAll = 'All';

export const MainFiltro: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const offset = 0;

  const tLinha = t('catalogFormSearchLine');
  const tFamilia = t('catalogFormSearchFamily');
  const tMontadora = t('catalogFormSearchCarManufacturer');
  const tModelo = t('catalogFormSearchVehicle');

  //Hooks
  // const lines = UseLines();
  const montadoras = UseMontadoras();

  //Services
  const { setSessionListaCatalogo } = UseSessionCatalogo();
  const { getFiltered, getLancamentos } = sessionCatalogoService();
  const { getAllFamilies, getFamiliesByCdLine } = sessionFamilyService();
  const { getModelosByCdLineCdMontadora } = sessionVehicleService();

  //SetStates
  const [families, setFamilies] = useState<IFamilyOptions[]>([]);
  const [vehicles, setVehicles] = useState<IModeloOptions[]>([]);

  const [cdIndustrial, setCdIndustrial] = useState('');
  const [dsProdCatalogo, setDsProdCatalogo] = useState('');
  const [textoAplicacao, setTextoAplicacao] = useState('');
  const [dsCatalogoLinha, setDsCatalogoLinha] = useState(tAll);
  const [dsCatalogoFamilie, setDsCatalogoFamilie] = useState(tAll); //Familia
  const [dsMontadora, setDsMontadora] = useState('');
  const [dsModelo, setDsModelo] = useState(tAll);
  const [defaultMontadoraDisabled, setDefaultMontadoraDisabled] = useState(true);
  const [defaultVeiculoDisabled, setDefaultVeiculoDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const lines: ISelectOptions[] = [
    { id: 0, label: tLinha, value: tLinha },
    { id: 1, label: t('linePassengerCar'), value: t('linePassengerCarValue') },
    { id: 2, label: t('lineHeavyDuty'), value: t('lineHeavyDutyValue') },
    { id: 3, label: t('lineAgriculturalMachinery'), value: t('lineAgriculturalMachineryValue') },
    { id: 4, label: t('lineVoltex'), value: t('lineVoltexValue') }
  ];

  const LoadSessionListaCatalogo = useCallback(
    (response: IListCatalogo[] | ((currVal: IListCatalogo[]) => IListCatalogo[])) => {
      setSessionListaCatalogo([]);
      setSessionListaCatalogo(response);
      navigate(`/catalogo`);
    },
    [navigate, setSessionListaCatalogo]
  );

  const showAlertMessage = (mensagem: string) => {
    setAlertMessage(mensagem);

    // Remover o alerta após alguns segundos
    setTimeout(() => {
      setAlertMessage(null);
    }, 5000); // 5000 milissegundos = 5 segundos
  };

  const handleSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setIsLoading(true);

      let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase();
      getFiltered(
        ParametrosPaginacao.PRODUCT_MAXLIMIT_SOURCE,
        offset,
        cdIndustrial,
        dsProdCatalogo,
        textoAplicacao,
        dsCatalogoLinha !== tAll ? dsCatalogoLinha : '',
        dsCatalogoFamilie !== tAll ? dsCatalogoFamilie : '', //Familia
        dsMontadora !== tAll ? dsMontadora : '',
        dsModelo !== tAll ? dsModelo : '',
        language
      ).then((response) => {
        if (response instanceof Error) {
          // console.log('response dentro: ', response);
          // alert(response.message);
          showAlertMessage(response.message); // Exibir a mensagem de erro como um alerta
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
          LoadSessionListaCatalogo(response);
          setCdIndustrial('');
          setDsProdCatalogo('');
          setTextoAplicacao('');
          setDsCatalogoLinha(tAll);
          setDsCatalogoFamilie(tAll);
        }
      });
    },
    [
      LoadSessionListaCatalogo,
      cdIndustrial,
      dsCatalogoFamilie,
      dsCatalogoLinha,
      dsModelo,
      dsMontadora,
      dsProdCatalogo,
      getFiltered,
      i18n.language,
      textoAplicacao
    ]
  );

  const handleFilterByLineSubmit = useCallback(
    (e: { preventDefault: () => void }, dsLinha: string) => {
      e.preventDefault();
      setIsLoading(true);

      getFiltered(ParametrosPaginacao.PRODUCT_MAXLIMIT_SOURCE, offset, '', '', '', dsLinha, '', '', '').then(
        (response) => {
          if (response instanceof Error) {
            alert(response.message);
            return;
          } else {
            setIsLoading(false);
            LoadSessionListaCatalogo(response);
          }
        }
      );
    },
    [LoadSessionListaCatalogo, getFiltered]
  );

  const handleFilterByLancamentosSubmit = useCallback(
    (e: { preventDefault: () => void }, lancamento: number) => {
      e.preventDefault();
      setIsLoading(true);

      getLancamentos(ParametrosPaginacao.PRODUCT_MAXLIMIT_SOURCE, offset, lancamento).then((response) => {
        if (response instanceof Error) {
          alert(response.message);
          return;
        } else {
          setIsLoading(false);
          LoadSessionListaCatalogo(response);
        }
      });
    },
    [LoadSessionListaCatalogo, getLancamentos]
  );

  useEffect(() => {
    let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase();
    if (dsMontadora === tAll || dsMontadora === '') {
      setDefaultVeiculoDisabled(true);
      setVehicles([]);
    } else {
      getModelosByCdLineCdMontadora(dsCatalogoLinha, dsMontadora).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
          return;
        } else {
          setVehicles(result);
          setDefaultVeiculoDisabled(false);
        }
      });
    }

    if (dsCatalogoLinha !== tAll) {
      getFamiliesByCdLine(dsCatalogoLinha, language).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
          return;
        } else {
          // setFamilies(result);
          setDefaultMontadoraDisabled(false);
        }
      });
    } else {
      getAllFamilies(language).then((result) => {
        // setIsLoading(true);
        if (result instanceof Error) {
          alert(result.message);
          return;
        } else {
          setFamilies(result);
          setDsMontadora(tAll);
          setDefaultMontadoraDisabled(true);
          setDefaultVeiculoDisabled(true);
        }
      });
    }
  }, [dsCatalogoLinha, dsMontadora, getAllFamilies, getFamiliesByCdLine, getModelosByCdLineCdMontadora, i18n.language]);

  return (
    <>
      <section className='FiltroClass MarginTopBody'>
        <form onSubmit={handleSubmit}>
          <Container>
            <Row className='line-1'>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={t('catalogFormSearchPlaceHolderIMCode')}
                    value={cdIndustrial}
                    onChange={(e) => setCdIndustrial(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={t('catalogFormSearchPlaceHolderPartName')}
                    value={dsProdCatalogo}
                    onChange={(e) => setDsProdCatalogo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={t('catalogFormSearchPlaceHolderApplication')}
                    value={textoAplicacao}
                    onChange={(e) => setTextoAplicacao(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label={tFamilia}
                  onChange={(e) => setDsCatalogoFamilie(e.target.value)}
                  value={dsCatalogoFamilie}
                >
                  <option>{tFamilia}</option>
                  {families &&
                    families.map((family) => (
                      <option key={family.cdFamilia} value={family.dsFamilia}>
                        {family.dsFamilia}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className='line-2'>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label='Linha'
                  name='selectLinha'
                  onChange={(e) => {
                    setDsCatalogoLinha(e.target.value);
                  }}
                  value={dsCatalogoLinha}
                >
                  {/* <option key={0} value={tAll}>{tLinha}</option> */}
                  {lines &&
                    lines.map((line) => (
                      <option key={line.id} value={line.value}>
                        {line.label}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label={tMontadora}
                  name={tMontadora}
                  onChange={(e) => setDsMontadora(e.target.value)}
                  disabled={defaultMontadoraDisabled}
                  value={dsMontadora}
                >
                  <option key={0} value={tAll}>
                    {tMontadora}
                  </option>
                  {montadoras &&
                    montadoras.map((montadora) => (
                      <option key={montadora.cdMontadora} value={montadora.dsMontadora}>
                        {montadora.dsMontadora}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label={tModelo}
                  name={tModelo}
                  onChange={(e) => setDsModelo(e.target.value)}
                  disabled={defaultVeiculoDisabled}
                  value={dsModelo}
                >
                  <option key={0} value={tAll}>
                    {tModelo}
                  </option>
                  {vehicles &&
                    vehicles.map((vehicle) => (
                      <option key={vehicle.cdModelo} value={vehicle.dsModelo}>
                        {vehicle.dsModelo}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                {isLoading ? (
                  <Button variant='secondary BtFiltro Loading' type='submit'>
                    <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>{' '}
                    {t('loading')}
                  </Button>
                ) : (
                  <Button variant='secondary BtFiltro' type='submit'>
                    {t('catalogBtnSearch')}
                  </Button>
                )}
              </Col>
            </Row>

            <Row className='line-3'>
              <Col className='BoxLinha'>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, 'Leve');
                    }}
                  >
                    <img className='IcoLeve' src={IcoLinhaLeve} alt='' />
                    <span>{t('linePassengerCar')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, 'Pesada');
                    }}
                  >
                    <img className='IcoPesada' src={IcoLinhaPesada} alt='' />
                    <span>{t('lineHeavyDuty')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, 'Agricola');
                    }}
                  >
                    <img className='IcoAgricola' src={IcoLinhaAgricola} alt='' />
                    <span>{t('lineAgriculturalMachinery')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, 'Voltex');
                    }}
                  >
                    <img className='IcoVoltex' src={IcoLinhaVoltex} alt='' />
                    <span>{t('lineVoltex')}</span>
                  </a>
                </div>
                <div className='ItenLinha ItemLancamento'>
                  <a
                    onClick={(e) => {
                      handleFilterByLancamentosSubmit(e, 1);
                    }}
                  >
                    <img className='IcoNavLancamentos' src={IcoNavLancamentos} alt='' />
                    <span>{t('newProducts')}</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
        {alertMessage && (
          <Container>
            <Row className='line-3'>
              <Col>
                <Col xs={12}>
                  <Alert
                    className={`alert-fade-in ${alertMessage ? 'show' : 'hide'}`}
                    // className={`fade ${alertMessage ? 'show' : ''}`}
                    variant='warning'
                    dismissible
                    onClose={() => setAlertMessage(null)}
                  >
                    <strong>Ops!</strong> {alertMessage}
                    {/* <Alert.Heading className='alert-heading'>{alertMessage}</Alert.Heading> */}
                  </Alert>
                </Col>
              </Col>
            </Row>
          </Container>
        )}
      </section>
      {/* End | FiltroClass */}
    </>
  );
};
