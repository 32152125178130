import { tAll } from '../../../components';
import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';
import { IListFotosCatalogo, IListCatalogo } from '../Interfaces';

type TFotoProduto = {
  produtoFotos: IListFotosCatalogo[];
};

type TProduto = {
  produtos: IListCatalogo[];
};

const getGeneralSearch = async (
  limit: number,
  offset: number,
  querySearch: string
): Promise<IListCatalogo[] | Error> => {
  try {
    const urlRelativa = `searchProdutos/${limit}/${offset}/1?apikey=${Environment.API_KEY}&query=${querySearch}`;
    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });

    return data.produtos;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos');
  }
};

const getAll = async (limit: number, offset: number): Promise<IListCatalogo[] | Error> => {
  try {
    const urlRelativa = `produtos/${limit}/${offset}?apikey=${Environment.API_KEY}`;

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });

    return data.produtos;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos');
  }
};

const getLancamentos = async (
  limit: number,
  offset: number,
  lancamento?: number | ''
): Promise<IListCatalogo[] | Error> => {
  try {
    const urlRelativa = `produtos/${limit}/${offset}?apikey=${Environment.API_KEY}&lancamento=${lancamento ?? ''}`;

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });

    return data.produtos;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos');
  }
};

const getFiltered = async (
  limit: number,
  offset: number,
  cdWeb?: string | '',
  dsProdCatalogo?: string | '',
  textoAplicacao?: string | '',
  dsCatalogoLinha?: string | '',
  dsFamilia?: string | '', //familia
  dsMontadora?: string | '',
  dsModelo?: string | '',
  language?: string | 'PT'
): Promise<IListCatalogo[] | Error> => {
  try {
    // console.log('language: ', language);
    // console.log('dsProdCatalogo: ', dsProdCatalogo);

    let urlRelativa = `produtos/${limit}/${offset}?apikey=${Environment.API_KEY}&cdWeb=${cdWeb ?? ''}&dsProdCatalogo=${
      dsProdCatalogo ?? ''
    }&textoAplicacao=${textoAplicacao ?? ''}&dsCatalogoLinha=${dsCatalogoLinha ?? ''}&dsCatalogoGrupo=${
      dsFamilia ?? ''
    }&idioma=${language}`;

    // console.log('URL:', urlRelativa);
    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });

    let produtosFiltrados: IListCatalogo[];

    if (dsMontadora && dsMontadora !== tAll) {
      produtosFiltrados = data.produtos.filter((montadora) =>
        montadora.prodMarcaModelo.some((marca) => marca.dsMarca === dsMontadora)
      );

      if (dsModelo !== tAll && produtosFiltrados && produtosFiltrados !== null) {
        produtosFiltrados = produtosFiltrados.filter((montadora) =>
          montadora.prodMarcaModelo.some((modelo) => modelo.dsModelo === dsModelo)
        );
      }

      return produtosFiltrados;
    } else {
      // console.log('data.produtos: ', data.produtos);
      if (!data.produtos) {
        const error = new Error(
          language === 'PT'
            ? Environment.LISTAGEM_VAZIA_PT
            : language === 'ES'
            ? Environment.LISTAGEM_VAZIA_ES
            : Environment.LISTAGEM_VAZIA_EN
        );
        return error;
      }
      return data.produtos;
    }
  } catch (error) {
    // console.log(error);
    throw new Error((error as { message: string }).message || 'Erro ao listar os produtos');
  }
};

const getFilteredByLine = async (
  limit: number,
  offset: number,
  dsCatalogoLinha?: string
): Promise<IListCatalogo[] | Error> => {
  try {
    const urlRelativa = `produtos/${limit}/${offset}?apikey=${Environment.API_KEY}&dsCatalogoLinha=${dsCatalogoLinha}`;

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });
    return data.produtos;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos');
  }
};

const getFotosCatalogo = async (cdIndustrial: string): Promise<IListFotosCatalogo[] | Error> => {
  try {
    const urlRelativa = `produtoFotos/${cdIndustrial}?apikey=${Environment.API_KEY}`;
    const { data } = await Api.get<TFotoProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    });
    return data.produtoFotos;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao obter foto do produto');
  }
};

const getById = async (cdOriginal: Number): Promise<IListCatalogo | Error> => {
  try {
    const urlRelativa = `/tt-produto?cdOriginal=${cdOriginal}`;
    // const urlRelativa = `/WEB_GERA_JSON_ITENS_31jan2023/json?apikey=${Environment.API_KEY}`

    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o produto.');
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o produto');
  }
};

export const sessionCatalogoService = () => ({
  getGeneralSearch,
  getAll,
  getLancamentos,
  getFiltered,
  getFilteredByLine,
  getFotosCatalogo,
  getById
});
