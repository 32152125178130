import { IListLinksNoticias } from '../../services/api/Interfaces';

interface ITextoConteudoProps {
  textoConteudo: string;
  noticiaLinks?: IListLinksNoticias[];
}

export const TextoConteudoNoticia: React.FC<ITextoConteudoProps> = ({ textoConteudo, noticiaLinks }) => {
  let textoSubstituido = textoConteudo;

  noticiaLinks &&
    noticiaLinks.forEach(({ stringAtalho, dsLegenda, linkExterno }) => {
      const regex = new RegExp(stringAtalho.replace(/\[|\]/g, '\\$&'), 'g');

      if (stringAtalho.indexOf('@') >= 0) {
        textoSubstituido = textoSubstituido.replace(
          regex,
          `<a href="maito:${linkExterno}" target='blank'><strong> ${dsLegenda}</strong></a>`
        );
      } else {
        textoSubstituido = textoSubstituido.replace(
          regex,
          `<a href="https://${linkExterno}" target='blank'><strong> ${dsLegenda}</strong></a>`
        );
      }
    });

  return <div dangerouslySetInnerHTML={{ __html: textoSubstituido }} />;
};
