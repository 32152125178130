export const LinksExternos = {
  LINKWEBPEDIDOS: 'http://webpedido.marilia-sa.com.br/Pedidos/Login.aspx',
  LINKWEBPEDIDOS2: 'http://webpedido.marilia-sa.com.br/webpedido/Pedidos/login.aspx',
  LINKWEBMAIL: 'https://webmail-seguro.com.br/marilia-sa.com.br/',
  LINKYOUTUBE: 'https://www.youtube.com/@Indmarilia/videos',
  LINKFACEBOOK: 'https://www.facebook.com/indmarilia/?locale=pt_PT',
  LINKINSTRAGRAM: 'https://www.instagram.com/indmarilia/',
  LINKLINKEDIN: 'https://br.linkedin.com/company/inmarilia',
};

export const ParametrosPaginacao = {
  PRODUCT_LIMIT_SOURCE: 12,
  PRODUCT_MAXLIMIT_SOURCE: 999999999,
};
