import { Route, Routes } from 'react-router-dom';

import { Contato, Home, Institucional, ListaCatalogo, Noticias, Qualidade, Representantes } from '../pages';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/home' element={<Home />} />
      <Route path='/institucional' element={<Institucional />} />
      <Route path='/qualidade' element={<Qualidade />} />
      <Route path='/noticias' element={<Noticias />} />
      <Route path='/catalogo' element={<ListaCatalogo />} />
      <Route path='/representantes' element={<Representantes />} />
      <Route path='/contato' element={<Contato />} />
      {/* <Route path='/noticias/noticia/:id' element={<Navigate to={"/noticias"} />} /> */}
      <Route path='*' element={<Home />} />
    </Routes>
  );
};
