import '../body/CatalogoBody';
import { ThumbCatalogoNoImage } from '../../../images';
import { useEffect, useState } from 'react';
import { IListFotosCatalogo } from '../../services/api/Interfaces';
import { sessionCatalogoService } from '../../services/api/catalogo/CatalogoService';

export interface IFotoProps {
  cdIndustrial: string;
  dsProdCatalogo: string;
}

export const Imagem: React.FC<IFotoProps> = ({ cdIndustrial, dsProdCatalogo }) => {
  const { getFotosCatalogo } = sessionCatalogoService();
  const [fotosCatalogo, setFotoCatalogo] = useState<IListFotosCatalogo[]>([]);

  useEffect(() => {
    // console.log('dsProdCatalogo: ', dsProdCatalogo);

    getFotosCatalogo(cdIndustrial).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      }
      const uniqueResult = [...new Set(result)];
      setFotoCatalogo(uniqueResult);
    });
  }, [cdIndustrial, dsProdCatalogo, getFotosCatalogo]);

  return (
    <>
      {fotosCatalogo.length >= 0 && fotosCatalogo[0] !== undefined && (
        <img
          key={fotosCatalogo[0].cdProdFoto}
          src={`${fotosCatalogo[0].base64Imagem ? fotosCatalogo[0].base64Imagem : ThumbCatalogoNoImage}`}
          alt={dsProdCatalogo}
          loading='lazy'
        />
      )}
    </>
  );
};
