import { ThumbNoLogo } from '../../../images';
import { ILogoRepresentante } from '../../services/api/Interfaces';

export interface TLogoProps {
  logos: ILogoRepresentante[];
}
export const LogoRepresentante: React.FC<TLogoProps> = ({ logos }) => {
  return (
    <>
      {logos &&
        logos.map((logo, index) => (
          <img key={index} src={`data:image/png;base64,${logo.base64Imagem}` ?? ThumbNoLogo} alt={logo.dsImagem} />
        ))}
    </>
  );
};
