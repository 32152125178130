import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IContato {
  nome?: string;
  email?: string;
  assunto: string;
  mensagem: string;
}

const sendContatoForm = async (formData: IContato): Promise<string | Error> => {
  try {
    const urlRelativa = `contato?apikey=${Environment.API_KEY}`;

    const response = await Api.post(urlRelativa, formData);
    if (response.data) {
      return response.data;
    }
    return new Error('Erro ao enviar o formulário');
  } catch (error) {
    console.error('Erro ao enviar o formulário:', error);
    return new Error((error as { message: string }).message || 'Erro ao enviar o formulário');
  }
};

export const sessionContatoService = () => ({
  sendContatoForm,
});
