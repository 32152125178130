import { t } from 'i18next';
import { ISelectOptions } from '../Interfaces/SelectOptions';

const lines = [
  { id: 1, label: t('linePassengerCar'), value: 'Leve' },
  { id: 2, label: t('lineHeavyDuty'), value: 'Pesada' },
  { id: 3, label: t('lineAgriculturalMachinery'), value: 'Agricola' },
  { id: 4, label: t('lineVoltex'), value: 'Voltex' },
];

const getAll = async (): Promise<ISelectOptions[] | Error> => {
  try {
    return lines;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar as linhas');
  }
};

export const sessionLineService = () => ({
  getAll,
});
