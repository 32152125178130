import '../body/HomeBody.css';
import '../body/CatalogoBody';
import { ThumbNoticia1 } from '../../../images';
import { IListFotosNoticia } from '../../services/api/Interfaces/ListFotosNoticias';

export interface IFotoProps {
  noticiaImagens?: IListFotosNoticia[];
}

export const ImagemNoticia: React.FC<IFotoProps> = ({ noticiaImagens }) => {
  return (
    <>
      {noticiaImagens && (
        <img
          className='ThumbNoticias'
          key={noticiaImagens[0].cdWebNoticiaImagem}
          src={noticiaImagens[0].caminhoARquivo ? noticiaImagens[0].caminhoARquivo : ThumbNoticia1}
          alt={noticiaImagens[0].nmArquivo}
          loading='lazy'
        />
      )}
    </>
  );
};
