export const Environment = {
  LIMITE_DE_LINHAS: 10,
  INPUT_DE_BUSCA: 'O que procura',
  LISTAGEM_VAZIA_PT: 'Nenhum registro foi encontrado!',
  LISTAGEM_VAZIA_ES: '¡No se encontraron registros!',
  LISTAGEM_VAZIA_EN: 'No records found!',

  // BASE_URL: 'http://localhost:3334/',

  BASE_URL: 'https://meli.marilia-sa.com.br:8091/newera/',
  API_KEY: '970df65621bcd5dd8c9d56022ac7ebed41ab328be653e793de4ae21a155f0039'
};
