import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import { BRA, ENG, SPA } from '../../../images';
import { useState } from 'react';

const languageOptions = [
  {
    name: '',
    value: 'ptBR',
    flag: BRA,
  },
  {
    name: '',
    value: 'en',
    flag: ENG,
  },
  {
    name: '',
    value: 'es',
    flag: SPA,
  },
];

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const [style, setStyle] = useState({ opacity: 0.5 });

  const focused = () => {
    setStyle({ opacity: 1 });
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    focused();
  };

  return (
    <>
      <section className=''>
        <div className='language-switcher'>
          {languageOptions.map((languageOption) => (
            <button
              className={'language-switcher'}
              key={languageOption.value}
              onClick={() => {
                changeLanguage(languageOption.value);
              }}
            >
              <img key={languageOption.value} src={languageOption.flag} alt='' style={style} />
              <span
                style={{
                  fontWeight: i18n.language === languageOption.value ? 'bold' : 'normal',
                  textDecoration: i18n.language === languageOption.value ? 'underline' : 'none',
                }}
              >
                {languageOption.name}
              </span>
            </button>
          ))}
        </div>
      </section>
    </>
  );
};
