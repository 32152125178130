import { useEffect, useState } from 'react';
import { IMontadoraOptions, sessionMontadoraService } from '../services/api/filtro/MontadoraService';

export const UseMontadoras = (): IMontadoraOptions[] => {
  const { getAllMontadoras } = sessionMontadoraService();
  const [montadoras, setMontadoras] = useState<IMontadoraOptions[]>([]);

  useEffect(() => {
    getAllMontadoras().then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      } else {
        setMontadoras(result);
      }
    });
  }, [getAllMontadoras]);
  return montadoras;
};
