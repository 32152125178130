import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IModeloOptions {
  cdModelo: string;
  dsModelo: string;
  cdLinha?: string;
  cdMontadora?: string;
}
type TModelo = {
  modelo: IModeloOptions[];
};

const getAllModelos = async (): Promise<IModeloOptions[] | Error> => {
  try {
    const urlRelativa = `LinFamMntMod?apikey=${Environment.API_KEY}`;

    const { data } = await Api.get<TModelo>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    return data.modelo;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os Vehicles dos produtos');
  }
};

const getModelosByCdLineCdMontadora = async (
  cdLinha?: String | undefined,
  cdMontadora?: string | undefined
): Promise<IModeloOptions[] | Error> => {
  try {
    const urlRelativa = `LinFamMntMod?apikey=${Environment.API_KEY}&Linha=${cdLinha}&Montadora=${cdMontadora}`;
    const { data } = await Api.get<TModelo>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    return data.modelo;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar os representantes');
  }
};

export const sessionVehicleService = () => ({
  getAllModelos,
  getModelosByCdLineCdMontadora,
});
