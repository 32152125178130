import { Environment } from '../../../enviroments';
import { IListFotosNoticia, IListLinksNoticias } from '../Interfaces';
import { Api } from '../axios-config';

export interface IListNoticia {
  cdWebNoticia: string;
  dtPublicacao: string;
  titulo: string;
  subTitulo: string;
  textoConteudo: string;
  destaque: string;
  noticiaImagens: IListFotosNoticia[];
  noticiaLinks: IListLinksNoticias[];
}

type TNoticias = {
  noticias: IListNoticia[];
};

const getAll = async (language: string): Promise<IListNoticia[] | Error> => {
  try {
    const urlRelativa = `noticias/${language}?apikey=${Environment.API_KEY}`;

    const { data } = await Api.get<TNoticias>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });
    return data.noticias.sort((a, b) => (a.dtPublicacao > b.dtPublicacao ? -1 : 1));
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar as notícias');
  }
};

const getDestak = async (language: string): Promise<IListNoticia[] | Error> => {
  try {
    const urlRelativa = `noticias/${language}?apikey=${Environment.API_KEY}`;

    const { data } = await Api.get<TNoticias>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });
    return data.noticias.filter((noticia) => noticia.destaque === 'S');
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar as notícias');
  }
};

const getById = async (cdWebNoticia: string, language: string): Promise<IListNoticia | Error> => {
  try {
    const urlRelativa = `noticias/${language}?apikey=${Environment.API_KEY}&cdNoticia=${cdWebNoticia}`;
    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar a notícia.');
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar a notícia');
  }
};

export const sessionNoticiasService = () => ({
  getAll,
  getDestak,
  getById,
});
