import { tAll } from '../../../components';
import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IFamilyOptions {
  cdFamilia: string;
  dsFamilia: string;
  cdLinha: string;
  language: string;
}
type TFamilia = {
  familia: IFamilyOptions[];
};

const getAllFamilies = async (language?: string): Promise<IFamilyOptions[] | Error> => {
  try {
    const urlRelativa = `LinFamMntMod/all?apikey=${Environment.API_KEY}&idioma=${language}`;
    // console.log(urlRelativa);
    const { data } = await Api.get<TFamilia>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    return data.familia;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar as famílias dos produtos');
  }
};

const getFamiliesByCdLine = async (cdLinha: String, language: String): Promise<IFamilyOptions[] | Error> => {
  try {
    // console.log('cdLinha:', cdLinha);

    const urlRelativa = `LinFamMntMod/all?apikey=${Environment.API_KEY}&Linha=${cdLinha}&idioma=${language}`;
    // console.log('urlRelativa:', urlRelativa);

    const { data } = await Api.get<TFamilia>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    // console.log('urlRelativa:', urlRelativa);
    let familyFilteredByLine: IFamilyOptions[];

    switch (cdLinha) {
      case 'Linha Leve':
      case 'Liviano':
      case 'Passenger Car':
        cdLinha = '1';
        break;
      case 'Linha Pesada':
      case 'Pesado':
      case 'Heavy Duty':
        cdLinha = '2';
        break;
      case 'Linha Agricola':
      case 'Agrícola':
      case 'Agricultural Machinery':
        cdLinha = '3';
        break;
      case 'Voltex':
        cdLinha = '4';
        break;
      default:
        break;
    }

    // console.log('cdLinha: ', cdLinha);

    if (cdLinha && cdLinha !== tAll) {
      familyFilteredByLine = data.familia.filter((linha) => linha.cdLinha.includes(cdLinha.toString()));
      // console.log('familyFilteredByLine: ', familyFilteredByLine);
      return familyFilteredByLine;
    }
    return data.familia;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar os representantes');
  }
};

export const sessionFamilyService = () => ({
  getAllFamilies,
  getFamiliesByCdLine,
});
