import { IListNoticia } from '../../services/api/noticias/NoticiasService';
import { ImagemNoticia, TextoConteudoNoticia } from '../generic';

interface INoticiaContentProps {
  noticia: IListNoticia | undefined;
}
export const NoticiasBodyDetail: React.FC<INoticiaContentProps> = ({ noticia }) => {
  return (
    <div key={Number(noticia?.cdWebNoticia)}>
      <div className='CapaDetalheNoticia'>
        <ImagemNoticia noticiaImagens={noticia && noticia.noticiaImagens} />
        <br />
        <br />
        <span className='box-yellow-date'>{noticia && String(noticia.dtPublicacao)}</span>
        <br />
        <h3 className='card-title'>{noticia && noticia.titulo}</h3>
        <h5 className='card-subtitle mb-2 text-muted'>{noticia && noticia.subTitulo}</h5>
        <div className='item-detailnoticia'>
          {noticia?.textoConteudo && (
            <TextoConteudoNoticia
              textoConteudo={noticia?.textoConteudo.replaceAll('<br><br>', ' \n\n')}
              noticiaLinks={noticia?.noticiaLinks}
            />
          )}
        </div>
      </div>
    </div>
  );
};
