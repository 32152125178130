import { Environment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IMontadoraOptions {
  cdMontadora: string;
  dsMontadora: string;
}
type TMontadora = {
  montadora: IMontadoraOptions[];
};

const getAllMontadoras = async (): Promise<IMontadoraOptions[] | Error> => {
  try {
    const urlRelativa = `LinFamMntMod?apikey=${Environment.API_KEY}`;

    const { data } = await Api.get<TMontadora>(urlRelativa, {
      headers: {
        Accept: 'application/json',
      },
    });

    return data.montadora;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar as famílias dos produtos');
  }
};

export const sessionMontadoraService = () => ({
  getAllMontadoras,
});
